<template>
  <div
    :class="[
      large ? largeClasses : wide ? wideClasses : normalClasses,
      extraArch ? 'border-2 border-white p-6 rounded-t-full' : '',
    ]">
    <div
      class="border border-white p-2 rounded-t-full"
      :class="[large ? largeClasses : wide ? wideClasses : normalClasses]">
      <img
        v-if="src"
        v-lazy="src"
        class="bg-cloudy-blue-700 border border-white object-cover rounded-t-full w-full h-full" />
    </div>
  </div>
</template>

<script setup lang="ts">
  defineProps({
    src: String,
    alt: String,
    large: Boolean,
    wide: Boolean,
    extraArch: Boolean,
  })

  const largeClasses = 'h-96 lg:h-144 w-64 lg:w-112'
  const wideClasses = 'w-72 md:w-96 h-96'
  const normalClasses = 'h-96 w-72'
</script>
